<template>
  <div>
    <van-swipe :autoplay="3000"
      @change="onChange">
      <van-swipe-item v-for="item in imgsList"
        :key="item+'11'">
        <img :src="item"
          style="width:100%;height:290px;" />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator"> <span style="font-size:16px;">{{ count + 1}}</span>/{{imgsList.length}}</div>
      </template>
    </van-swipe>
    <div style="padding: 10px 5%;">
      <div class="typeTitle"
        style="font-size:20px;">{{details.Name}}</div>
      <div style="margin:10px 0;color:#666666;">酒店类型：{{details.CategoryDesc}}</div>
    </div>
    <div class="mapImg"
      style="height: 70px;">
      <div style="padding:10px 5%;">
        <img src="https://xczxapi.zhsq.cloud//upload/images/2022/6/1e7faeee-544.png"
          alt=""
          style="width:20px;">
        <span class="address"
          style="margin-left:10px;">{{details.Address}}</span>
        <div style="float: right;color:#31D984;padding-top: 5px;"
          @click="gotominiProgram(details)">地图
          <van-icon name="arrow"
            color="#999999" />
        </div>
      </div>

    </div>
    <div class="border">
      <div class="typeTitle">开放时间</div>
      <div>{{details.BusinessTime}}</div>
    </div>
    <div class="border">
      <div class="typeTitle">联系电话</div>
      <div style="color:#666666;">联系电话：
        <span>
          <a :href="'tel:' + details.TouchTel"
            style="color:#31D984;">{{ details.TouchTel }}</a>
        </span> </div>
    </div>
    <div class="border">
      <div class="jieshao">简介</div>
      <div class="Introduce">{{details.Introduce}}</div>
      <!-- <div style="margin:10px 0;"
        v-if="imgsList.length != '0'">
        <div v-for="item in imgsList"
          :key="item">
          <img :src="item"
            style="width:100%;"
            alt="">
        </div>

      </div> -->
    </div>
  </div>
</template>

<script>
import { WeGetHotelByHotelId } from "@/api/jingqu";
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data () {
    return {
      count: '',
      details: {},
      imgsList: [],
      nameCc: {},
    }
  },
  created () {

    this.getHotelByHotelId()
  },
  methods: {
    onChange (index) {
      this.count = index;
    },
    getHotelByHotelId () {
      console.log(this.$route.params.Id);
      WeGetHotelByHotelId({ HotelId: this.$route.params.Id }).then((res) => {
        this.details = res.data.data
        if (res.data.data.ImgUrls != '') {
          this.imgsList = res.data.data.ImgUrls.split(",")
        }
      })
    },
    gotominiProgram (details) {
      console.log(details);
      if (details.Coordinate == '') {
        Toast.fail('暂无地理位置');
      } else {
        this.nameCc = {
          Navigation: '导航',
          Coordinate: details.Coordinate
        }
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url: "../public/openminip?name=" + JSON.stringify(this.nameCc),
          });
        } else {
          alert("请在小程序中打开");
        }
      }

    },
  },
}
</script>

<style scoped>
.custom-indicator {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 2px 5px;
  font-size: 14px;
  color: #ffffff;
  /* background: rgba(255, 255, 255, 0.788); */
}
.border {
  padding: 10px 5%;
  border-top: 15px solid #f9f9f9;
  line-height: 30px;
}
.typeTitle {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: bold;
}
.Introduce {
  text-indent: 2em;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
  color: #0c0c0c;
}
.jieshao {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.mapImg {
  background-image: url('https://xczxapi.zhsq.cloud//upload/images/2022/6/1ee98423-7b1.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.mapImg div {
  /* line-height: 30px; */
}
.address {
  margin-left: 10px;
  line-height: 30px;
  position: absolute;
  word-wrap: break-word;
  width: 65%;
}
</style>